<template>
  <div>
    <vx-card>
      <iframe
          src="https://stats.uptimerobot.com/WQDMwU6kxn"
          width="100%"
          height="1500px"
          frameborder="0" >
      </iframe>
    </vx-card>
  </div>
</template>

<script>
import ApiService from "../../../api";

import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";

export default {
  name: "domain-uptime-robot",
  components: {
  },
  data() {
    return {}
  },
  created() {

  },
  computed: {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>